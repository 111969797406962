import { useEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "../api"
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg"
import { Each } from "../common/Each"
import { CourseStatus, CourseType } from "../common/constants"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import Button from "../components/Button"
import LessonsCarousel from "../components/LessonsCarousel"
import Loader from "../components/Loader"
import MultiStateSwitch from "../components/MultiStateSwitch"
import CourseCard from "../components/cards/CourseCard"
import PathCourseCard from "../components/cards/PathCourseCard"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import typo from "../typography.module.css"
import styles from "./MyPath.module.css"

const MyPath = () => {

    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const navigate = useNavigate()
    const [lessons, setLessons] = useState([])
    const [loading, setLoading] = useState(false)
    const [coursesLoading, setCoursesLoading] = useState(false)

    const [courses, setCourses] = useState([])
    const [filterCourses, setFilterCourses] = useState([])

    const [professionalCourse, setProfessionalCourse] = useState(null)
    const [filterState, setFilterState] = useState(0)

    useEffect(() => {

        window.scrollTo({
            top: 0,
        })

        const getLessons = async () => {
            setLoading(true)
            try {
                let lessons = await api.get("/user/lessons")
                setLessons(lessons)
            }
            catch (e) {
                console.error(e)
            }
            setLoading(false)
        }

        const getCourses = async () => {
            setCoursesLoading(true)
            try {
                let courses = await api.get("/user/courses")
                setCourses(courses)
                setFilterCourses(courses)
                let professional = courses.find(c => c.type === CourseType.Professional)
                if (professional) {
                    setProfessionalCourse(professional)
                }
            }
            catch (e) {
                console.error(e)
            }
            setCoursesLoading(false)
        }

        getCourses()
        getLessons()
    }, [])


    const handleCoursesFilterChange = (index) => {
        var filtered = []
        switch (index) {
            case 0:
                setFilterCourses(courses)
                break;
            case 1:
                filtered = courses.filter(c => c.status === CourseStatus.InProgress)
                setFilterCourses(filtered)
                break;
            case 2:
                filtered = courses.filter(c => c.status === CourseStatus.ToStart)
                setFilterCourses(filtered)
                break;
            case 3:
                filtered = courses.filter(c => c.status === CourseStatus.Ended)
                setFilterCourses(filtered)
                break;
            default:
                setFilterCourses(courses)
        }
        setFilterState(index)
    }

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Il mio percorso</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title}>
                            {t('myPath.title')}
                        </div>
                        {/* LESSONS */}
                        <LessonsCarousel lessons={lessons} courses={courses} loading={loading} />

                        {/* PROFESSIONAL COURSE */}
                        {professionalCourse &&
                            <>
                                <div className={typo.title} style={{ fontSize: '1.25rem' }}>
                                    {t('myPath.professionalCourse')}
                                </div>
                                {width <= 540 &&
                                    <CourseCard course={professionalCourse} cardType="myPath" />
                                }
                                {width > 540 &&
                                    <PathCourseCard course={professionalCourse} />
                                }
                            </>
                        }

                        {/* ALL COURSES */}
                        <div className={typo.title} style={{ fontSize: '1.25rem' }}>
                            {t('myPath.myCourses')}
                        </div>
                        <MultiStateSwitch
                            states={[t('myPath.all').toUpperCase(), t('myPath.inProgress').toUpperCase(), t('myPath.toStart').toUpperCase(), t('myPath.ended').toUpperCase()]}
                            onStateChange={handleCoursesFilterChange}
                        />
                        {coursesLoading &&
                            <div className={styles.loaderContainer}>
                                <Loader />
                            </div>
                        }
                        {!coursesLoading &&
                            <>

                                {courses.length === 0 &&
                                    <div className={styles.noCourses}>
                                        <span><Trans i18nKey="myPath.noCourses"></Trans></span>
                                        <Button
                                            onClick={() => { navigate("/courses") }}
                                            style={{ display: 'flex', alignItems: 'center', padding: '.75rem 3rem', backgroundColor: 'var(--background-secondary-color)' }} inverse>
                                            {t('myPath.goToCourses').toUpperCase()}
                                            <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                                        </Button>
                                    </div>
                                }
                                {courses.length > 0 &&
                                    <>
                                        {
                                            filterCourses.length > 0 &&
                                            <Each of={filterCourses} render={(course) => {
                                                return (
                                                    <PathCourseCard course={course} />
                                                )
                                            }} />
                                        }
                                        {filterCourses.length === 0 && filterState === 1 &&
                                            <div className={styles.noCourses}>
                                                <span><Trans i18nKey="myPath.noInProgressCourses"></Trans></span>
                                            </div>
                                        }

                                        {filterCourses.length === 0 && filterState === 3 &&
                                            <div className={styles.noCourses}>
                                                <span><Trans i18nKey="myPath.noEndedCourses"></Trans></span>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        }

                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )

}

export default MyPath