import { useEffect, useState } from "react";
import { palette } from "../common/constants";
import { Each } from "../common/Each";
import Skeleton from "./Skeleton";
import styles from "./TeachersBadge.module.css";

const TeachersBadge = ({
  teachers = [],
  pictureStyle = {},
  nameStyle = {},
  qualificationStyle = {},
  centered = false,
  showQualification = false,
  loading = false,
  roles = ['teacher'],
  style,
}) => {

  const [t, setT] = useState(teachers.filter(t => roles.includes(t.role)))

  useEffect(() => {
    setT(teachers.filter(t => roles.includes(t.role)));
  }, [teachers, roles])

  return (
    <>
      {
        loading &&
        <div className={centered ? styles.centered : styles.teachers} style={{ gap: "1rem" }}>
          <Skeleton type="circle" width={"48px"} height={"48px"} />
          <div className={styles.teacherInfo} style={{ display: "flex", flexDirection: "column", gap: "0.2rem", width: "100%", paddingLeft: 0 }}>
            <Skeleton type="rect" width={"100%"} height={"20px"} borderRadius={"6px"} />
            <Skeleton type="rect" width={"66%"} height={"14px"} borderRadius={"6px"} />
          </div>
        </div>
      }

      {
        !loading &&
        <div className={centered ? styles.centered : styles.teachers} style={style}>
          {teachers.length === 1 && (
            <>
              <img
                className={styles.teacherPicture}
                src={teachers[0].picture}
                alt={`${teachers[0].surname}`}
                style={pictureStyle}
              />
              <div className={styles.teacherInfo}>
                <div className={styles.teacherName} style={nameStyle}>
                  {teachers[0].name} {teachers[0].surname}
                </div>
                {teachers[0].qualification && showQualification && (
                  <div
                    className={styles.teacherQualification}
                    style={qualificationStyle}
                  >
                    {teachers[0].qualification}
                  </div>
                )}
              </div>
            </>
          )}
          {teachers.length > 1 && (
            <Each
              of={t}
              render={(teacher, index) => {
                return (
                  <img
                    className={styles.teacherPicture}
                    style={{
                      zIndex: index,
                      border: `1.5px solid ${palette[index]}`,
                      transform: `translateX(${-20 * index}%)`,
                      ...pictureStyle,
                    }}
                    src={teacher.picture}
                    alt={`${teacher.surname}`}
                  />
                );
              }}
            />
          )}
        </div>
      }
    </>

  );
};

export default TeachersBadge;
