import { auth } from './firebase'

let socket = null;
let reconnectInterval = null;
const RECONNECT_TIMEOUT = 5000;

const initWebSocket = async (firebaseId, onMessageReceived) => {
    const token = await auth.currentUser.getIdToken()
    socket = new WebSocket(`wss://api-beta.velv.academy/ws/messaging/${firebaseId}?token=${token}&type=user`);

    socket.onopen = () => {
        console.log('WebSocket connection opened.');
        clearTimeout(reconnectInterval);
        document.dispatchEvent(new CustomEvent('websocket-connected'));
    };

    socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        onMessageReceived(data); // Richiama la funzione fornita per gestire il messaggio ricevuto
    };

    socket.onclose = () => {
        console.log('WebSocket connection closed.');
        document.dispatchEvent(new CustomEvent('websocket-reconnecting'));
        reconnectInterval = setTimeout(() => {
            document.dispatchEvent(new CustomEvent('websocket-reconnecting'));
            initWebSocket(firebaseId, onMessageReceived); // Tentativo di riconnessione
        }, RECONNECT_TIMEOUT);
    };

    socket.onerror = (error) => {
        console.error('WebSocket error:', error);
    };
};

const sendMessage = (message) => {
    if (socket) {
        try {
            socket.send(JSON.stringify(message));
        }
        catch (e) {
            console.error(e)
        }
    }
    else {
        console.log("Socket is null")
    }
};

const closeWebSocket = () => {
    if (socket) {
        socket.close();
        socket = null;
        clearTimeout(reconnectInterval);
    }
};

export { initWebSocket, sendMessage, closeWebSocket };